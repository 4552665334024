import React, { useEffect, useState } from 'react';
import Styles from './Publish.module.scss';

import wordpress from '../../assets/publish/wordpress.svg';
import Dotsverticle from '../../assets/publish/Icondots.svg';
import Wix from '../../assets/publish/Wix.svg';
import CMS from '../../assets/publish/CMS.svg';
import Contentful from '../../assets/publish/Contentful.svg';
import Shopify from '../../assets/publish/Shopify.svg';
import Squarespace from '../../assets/publish/Squarespace.svg';
import Hubspot from '../../assets/publish/Hubspot.svg';
import Storyblock from '../../assets/publish/Storyblock.svg';
import Medium from '../../assets/publish/Medium.svg';
//

import Form from 'react-bootstrap/Form';

import StylesIntegrationCard from '../../Containers/Integrations/Publish.module.scss';

import {
  error as errorToast,
  success as successToast,
} from 'helpers/toaster.js';

import {
  getUserIntegrations,
  publishDocuments,
} from 'services/integrationService';
import { useParams } from 'react-router-dom';
import BSButton from 'Components/Button/BSButton';
import Settings from './Settings';

const Publish = ({
  setPublishSelectIntegration,
  publishSelectedIntegration,
  displaySettings,
  setDisplaySettings,
  checkIntegrations,
  handlePublishDoc,
}) => {
  //
  const [integrations, setIntegrations] = useState(null);
  const { documentId } = useParams() || {};

  //
  useEffect(() => {
    fetchUserIntegrations();
  }, []);

  // useEffect(() => {
  //   return () => {
  //     setPublishSelectIntegration({});
  //     // setDisplaySettings(false)
  //   };
  // }, []);

  const showSettings = () => {
    if (!checkIntegrations(publishSelectedIntegration)) {
      errorToast('Please select a channel to proceed.');
      // You can set an error message or take other actions here
      return;
    }
    setDisplaySettings(true);
  };

  const fetchUserIntegrations = async () => {
    try {
      const { data } = await getUserIntegrations();

      setIntegrations(data?.platform_integrations);
    } catch (error) {
      errorToast(error);
    }
  };
  const onClickIntegration = async (integrationId, status) => {
    try {
      await publishDocuments(documentId, {
        integration_id: integrationId,
        status,
      });
      successToast(`Document published with the state: ${status}`);
      // props.onHide();
    } catch (error) {
      // props.onHide();
      // setShowErrorModal(true);
    }
  };
  return (
    <div className={Styles.publish_container}>
      <div className={Styles.main_channel_container}>
        <h3 className={Styles.heading_channel}>Connected Channels</h3>
        <div className={Styles.row_inline}>
          {integrations?.map((integration) => (
            <div key={integration.name}>
              <IntegrationCard
                integration={integration}
                setPublishSelectIntegration={setPublishSelectIntegration}
                publishSelectedIntegration={publishSelectedIntegration}
              />
            </div>
          ))}
        </div>
        {/* <button>Next</button> */}
        {/* {!displaySettings && <BSButton
          variant="light"
          ButtonText="Next"
          classList="btn getStartedBtn"
          onClick={()=>showSettings()}
        />} */}
      </div>
      {/* {displaySettings && <div>
        <Settings/>
      </div>} */}
      <div className={Styles.available_channel_container}>
        <h3 className={Styles.heading_channel}>
          Available Channels to Connect
        </h3>
        <div className={Styles.row_inline}>
          <div className={Styles.available_channel}>
            <div className={Styles.d_flex_channel_logo_box}>
              <div className={Styles.channel_logo_name}>
                <div className={Styles.channel_logo}>
                  <img src={Contentful} alt="wordpress" />
                </div>
                <p className={Styles.logo_name}>Contentful</p>
              </div>
              <div className={Styles.coming_label}>Coming soon</div>
            </div>
            <div className={Styles.detail_text_box}>
              <p className={Styles.para_avail_channel}>
                Publish articles to your contentful website directly from
                Vocable.
              </p>
            </div>
            <button type="buttob" className={Styles.button_connect}>
              Connect
            </button>
          </div>
          <div className={Styles.available_channel}>
            <div className={Styles.d_flex_channel_logo_box}>
              <div className={Styles.channel_logo_name}>
                <div className={Styles.channel_logo}>
                  <img src={Shopify} alt="wordpress" />
                </div>
                <p className={Styles.logo_name}>Shopify</p>
              </div>
              <div className={Styles.coming_label}>Coming soon</div>
            </div>
            <div className={Styles.detail_text_box}>
              <p className={Styles.para_avail_channel}>
                Publish articles to your contentful website directly from
                Vocable.
              </p>
            </div>
            <button type="buttob" className={Styles.button_connect}>
              Connect
            </button>
          </div>
          <div className={Styles.available_channel}>
            <div className={Styles.d_flex_channel_logo_box}>
              <div className={Styles.channel_logo_name}>
                <div className={Styles.channel_logo}>
                  <img src={Squarespace} alt="wordpress" />
                </div>
                <p className={Styles.logo_name}>Squarespace</p>
              </div>
              <div className={Styles.coming_label}>Coming soon</div>
            </div>
            <div className={Styles.detail_text_box}>
              <p className={Styles.para_avail_channel}>
                Publish articles to your contentful website directly from
                Vocable.
              </p>
            </div>
            <button type="buttob" className={Styles.button_connect}>
              Connect
            </button>
          </div>
          <div className={Styles.available_channel}>
            <div className={Styles.d_flex_channel_logo_box}>
              <div className={Styles.channel_logo_name}>
                <div className={Styles.channel_logo}>
                  <img src={Hubspot} alt="wordpress" />
                </div>
                <p className={Styles.logo_name}>Hubspot</p>
              </div>
              <div className={Styles.coming_label}>Coming soon</div>
            </div>
            <div className={Styles.detail_text_box}>
              <p className={Styles.para_avail_channel}>
                Publish articles to your contentful website directly from
                Vocable.
              </p>
            </div>
            <button type="buttob" className={Styles.button_connect}>
              Connect
            </button>
          </div>
          <div className={Styles.available_channel}>
            <div className={Styles.d_flex_channel_logo_box}>
              <div className={Styles.channel_logo_name}>
                <div className={Styles.channel_logo}>
                  <img src={wordpress} alt="wordpress" />
                </div>
                <p className={Styles.logo_name}>Wordpress</p>
              </div>
              <div className={Styles.coming_label}>Coming soon</div>
            </div>
            <div className={Styles.detail_text_box}>
              <p className={Styles.para_avail_channel}>
                Publish articles to your contentful website directly from
                Vocable.
              </p>
            </div>
            <button type="buttob" className={Styles.button_connect}>
              Connect
            </button>
          </div>
          <div className={Styles.available_channel}>
            <div className={Styles.d_flex_channel_logo_box}>
              <div className={Styles.channel_logo_name}>
                <div className={Styles.channel_logo}>
                  <img src={Wix} alt="wordpress" />
                </div>
                <p className={Styles.logo_name}>Wix</p>
              </div>
              <div className={Styles.coming_label}>Coming soon</div>
            </div>
            <div className={Styles.detail_text_box}>
              <p className={Styles.para_avail_channel}>
                Publish articles to your contentful website directly from
                Vocable.
              </p>
            </div>
            <button type="buttob" className={Styles.button_connect}>
              Connect
            </button>
          </div>
          <div className={Styles.available_channel}>
            <div className={Styles.d_flex_channel_logo_box}>
              <div className={Styles.channel_logo_name}>
                <div className={Styles.channel_logo}>
                  <img src={CMS} alt="wordpress" />
                </div>
                <p className={Styles.logo_name}>Webflow</p>
              </div>
              <div className={Styles.coming_label}>Coming soon</div>
            </div>
            <div className={Styles.detail_text_box}>
              <p className={Styles.para_avail_channel}>
                Publish articles to your contentful website directly from
                Vocable.
              </p>
            </div>
            <button type="buttob" className={Styles.button_connect}>
              Connect
            </button>
          </div>
          <div className={Styles.available_channel}>
            <div className={Styles.d_flex_channel_logo_box}>
              <div className={Styles.channel_logo_name}>
                <div className={Styles.channel_logo}>
                  <img src={Medium} alt="wordpress" />
                </div>
                <p className={Styles.logo_name}>Medium</p>
              </div>
              <div className={Styles.coming_label}>Coming soon</div>
            </div>
            <div className={Styles.detail_text_box}>
              <p className={Styles.para_avail_channel}>
                Publish articles to your contentful website directly from
                Vocable.
              </p>
            </div>
            <button type="buttob" className={Styles.button_connect}>
              Connect
            </button>
          </div>
          <div className={Styles.available_channel}>
            <div className={Styles.d_flex_channel_logo_box}>
              <div className={Styles.channel_logo_name}>
                <div className={Styles.channel_logo}>
                  <img src={Storyblock} alt="wordpress" />
                </div>
                <p className={Styles.logo_name}>Storyblock</p>
              </div>
              <div className={Styles.coming_label}>Coming soon</div>
            </div>
            <div className={Styles.detail_text_box}>
              <p className={Styles.para_avail_channel}>
                Publish articles to your contentful website directly from
                Vocable.
              </p>
            </div>
            <button type="buttob" className={Styles.button_connect}>
              Connect
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Publish;

const IntegrationCard = ({
  integration,
  setPublishSelectIntegration,
  publishSelectedIntegration,
}) => {
  return (
    <div className={StylesIntegrationCard.publish_container_new}>
      <div
        className={StylesIntegrationCard.main_channel_container}
        style={{ width: '380px' }}
      >
        <div className={StylesIntegrationCard.card_channel}>
          <div className={StylesIntegrationCard.d_flex_channel_logo_box}>
            <div className={StylesIntegrationCard.channel_logo_name}>
              <div className={StylesIntegrationCard.channel_logo}>
                <img src={`/Images/${integration.type}.svg`} alt="wordpress" />
              </div>
              <p className={StylesIntegrationCard.logo_name}>
                {integration?.name}
              </p>
            </div>
          </div>
          <div className={StylesIntegrationCard.detail_section}>
            <ul className={StylesIntegrationCard.detail_list}>
              <li className={StylesIntegrationCard.list_detail_item}>
                Integration
              </li>
              <li className={StylesIntegrationCard.list_name_item}>
                {integration?.account}
              </li>
            </ul>
            <ul className={StylesIntegrationCard.detail_list}>
              <li className={StylesIntegrationCard.list_detail_item}>
                Website
              </li>
              <li className={StylesIntegrationCard.list_name_item}>
                {integration?.site_identification}
              </li>
            </ul>
          </div>
          <div className={StylesIntegrationCard.active_section}>
            <p className={StylesIntegrationCard.active_status}>Select:</p>
            <p className={StylesIntegrationCard.active_status}>
              <Form>
                <div
                  className={
                    StylesIntegrationCard.outerContainer__card__right__switch
                  }
                >
                  <Form.Check
                    type="checkbox"
                    // checked={integration.is_active}
                    isChecked={
                      publishSelectedIntegration[integration.name]?.is_active
                    }
                    onChange={(e) => {
                      // setPublishSelectIntegration(integration);

                      setPublishSelectIntegration((prevState) => ({
                        ...prevState,
                        [integration.name]: {
                          ...integration, // store the entire integration information
                          is_active: !prevState[integration.name]?.is_active, // toggle checked status
                        },
                      }));
                    }}
                  />
                </div>
              </Form>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
