import React from 'react';
import { Card, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
// import Styles from './Integrations.module.scss';
import Styles from './Publish.module.scss';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FiEdit, FiTrash2 } from 'react-icons/fi';

const IntegrationCard = ({
  integration,
  handleActive,
  handleUpdate,
  handleDelete,
}) => {
  return (
    // <Card bg={integration.is_active ? 'white' : 'light'}>
    //   <Card.Img
    //     className={'d-flex p-3 mx-auto ' + Styles.CardImage}
    //     src={`/Images/${integration.type}.png`}
    //   />
    //   <Card.Title className="px-4">{integration.name}</Card.Title>
    //   <Card.Body className="d-flex justify-content-between align-items-center pt-1 px-4">
    // <Form.Check
    //   type="switch"
    //   className={Styles.CardActionSwitch}
    //   checked={integration.is_active}
    //   onChange={(e) => {
    //     handleActive(integration);
    //   }}
    // />
    //     <div className="d-flex gap-3">
    //       <Button
    //         className={Styles.CardActionBtn}
    //         variant="outline-light"
    //         onClick={(e) => {
    //           e.preventDefault();
    //           handleUpdate(integration);
    //         }}
    //       >
    //         <AiOutlineEdit size={22} color="#344054" />
    //       </Button>
    //       <Button
    //         className={Styles.CardActionBtn}
    //         variant="outline-light"
    //         onClick={(e) => {
    //           e.preventDefault();
    //           handleDelete(integration);
    //         }}
    //       >
    //         <AiOutlineDelete size={22} color="#344054" />
    //       </Button>
    //     </div>
    //   </Card.Body>
    // </Card>
    <div className={Styles.publish_container_new}>
      <div className={Styles.main_channel_container}>
        <div className={Styles.card_channel}>
          <div className={Styles.d_flex_channel_logo_box}>
            <div className={Styles.channel_logo_name}>
              <div className={Styles.channel_logo}>
                <img src={`/Images/${integration.type}.svg`} alt="wordpress" />
              </div>
              <p className={Styles.logo_name}>{integration?.name}</p>
            </div>

            <DropdownButton
              title={<BsThreeDotsVertical color="#182230" />}
              // onClick={handlePropagation}
            >
              <Dropdown.Item onClick={() => handleUpdate(integration)}>
                <div className={Styles.DropdownItem}>
                  <FiEdit size={14} />
                  <p className={Styles.DropdownItem_Text}>Edit</p>
                </div>
              </Dropdown.Item>

              <Dropdown.Item onClick={() => handleDelete(integration)}>
                <div className={Styles.DropdownItem}>
                  <FiTrash2 size={14} />
                  <p className={Styles.DropdownItem_Text}>Delete</p>
                </div>
              </Dropdown.Item>
            </DropdownButton>
          </div>
          <div className={Styles.detail_section}>
            <ul className={Styles.detail_list}>
              <li className={Styles.list_detail_item}>Integration</li>
              <li className={Styles.list_name_item}>{integration?.account}</li>
            </ul>
            <ul className={Styles.detail_list}>
              <li className={Styles.list_detail_item}>Website</li>
              <li className={Styles.list_name_item}>
                {integration?.site_identification}
              </li>
            </ul>
          </div>
          <div className={Styles.active_section}>
            <p className={Styles.active_status}>Active:</p>
            <p className={Styles.active_status}>
              <Form>
                <div className={Styles.outerContainer__card__right__switch}>
                  {/* <Form.Check type="switch" id="custom-switch" label="" /> */}
                  <Form.Check
                    type="switch"
                    className={Styles.CardActionSwitch}
                    checked={integration.is_active}
                    onChange={(e) => {
                      handleActive(integration);
                    }}
                  />
                </div>
              </Form>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntegrationCard;
