import React from 'react';
import Notifications from 'Components/Notifications/Notifications';
import Profile from 'Components/Profile/Profile';
import HeaderStyles from './DashboardHeader.module.scss';
import {
  FiGrid,
  FiLayers,
  FiList,
  FiSearch,
  FiUsers,
  FiZap,
  FiDollarSign,
  FiFileText,
  FiSettings,
  FiFile,
  FiMenu,
} from 'react-icons/fi';
import { FaRegFolder } from 'react-icons/fa6';
import { FaBars } from 'react-icons/fa6';
import { FaRegSmile } from 'react-icons/fa';
import { FaRegFaceSmile } from 'react-icons/fa6';
import { ReactComponent as Kanban } from 'assets/svg/kanban.svg';
import { LuCreditCard } from 'react-icons/lu';
import { BsLayers } from 'react-icons/bs';

import { HiOutlineEmojiHappy, HiOutlineLightBulb } from 'react-icons/hi';
import { ReactComponent as Plan } from 'assets/svg/trello.svg';

import { useLocation } from 'react-router-dom';
import { HeaderBadge } from './HeaderBadge';
import SearchInput from 'ui/SearchInput/SearchInput';

const DashboardHeader = ({ onHamburgerClick }) => {
  const location = useLocation();

  return (
    <div
      className={
        HeaderStyles.Dashboard_header +
        ' d-flex justify-content-between align-items-center'
      }
    >
      <button className={HeaderStyles.hamburger} onClick={onHamburgerClick}>
        <FaBars />
      </button>

      <div>
        <HeaderBadge
          icon={
            location.pathname === '/content-planner'
              ? [<Plan />]
              : location.pathname === '/new-plan'
                ? [<Plan />, <FiFileText />]
                : location.pathname === '/projects'
                  ? [<FiLayers />]
                  : location.pathname.startsWith('/projects/') &&
                      location.pathname.split('/').length >= 3
                    ? [<FiLayers />, <BsLayers />]
                    : location.pathname === '/library' ||
                        location.pathname.startsWith('/folder')
                      ? [<FaRegFolder />]
                      : location.pathname === '/templates'
                        ? [<FiZap />]
                        : location.pathname.startsWith('/questionnaire')
                          ? [<FiZap />]
                          : location.pathname.startsWith('/teams')
                            ? [<FiUsers />]
                            : location.pathname === '/research'
                              ? [<FiSearch />]
                              : location.pathname === '/ideate'
                                ? [<HiOutlineLightBulb />]
                                : location.pathname === '/billing-subscription'
                                  ? [<LuCreditCard />]
                                  : location.pathname === '/subscription'
                                    ? [<LuCreditCard />]
                                    : location.pathname === '/billing-payment'
                                      ? [<LuCreditCard />, <FiDollarSign />]
                                      : location.pathname.startsWith(
                                            '/freestyle-editor/document/',
                                          )
                                        ? [<FiFileText />]
                                        : location.pathname.startsWith(
                                              '/editor',
                                            )
                                          ? [<FiFileText />]
                                          : location.pathname === '/brand'
                                            ? [<FaRegFaceSmile />]
                                            : location.pathname ===
                                                '/integrations'
                                              ? [<Kanban />]
                                              : [<FiGrid />]
          }
          text={
            location.pathname === '/content-planner'
              ? ['Plan']
              : location.pathname === '/projects'
                ? ['Campaigns']
                : location.pathname.startsWith('/projects/') &&
                    location.pathname.split('/').length >= 3
                  ? ['Campaigns', ' Campaign detail']
                  : location.pathname.startsWith('/new-plan')
                    ? ['Plan', 'Create Content']
                    : location.pathname === '/library' ||
                        location.pathname.startsWith('/folder')
                      ? ['Library']
                      : location.pathname === '/brand'
                        ? ['Brand']
                        : location.pathname === '/templates'
                          ? ['Templates']
                          : location.pathname.startsWith('/teams')
                            ? ['Teams']
                            : location.pathname === '/research'
                              ? ['Research']
                              : location.pathname === '/ideate'
                                ? ['Ideate']
                                : location.pathname === '/billing-subscription'
                                  ? ['Billing']
                                  : location.pathname === '/subscription'
                                    ? ['Billing']
                                    : location.pathname === '/billing-payment'
                                      ? ['Billing', 'Payment']
                                      : location.pathname === '/onboarding/'
                                        ? ['Onboarding']
                                        : location.pathname.startsWith(
                                              '/freestyle-editor/document/',
                                            )
                                          ? ['Document']
                                          : location.pathname.startsWith(
                                                '/editor',
                                              )
                                            ? ['Document']
                                            : location.pathname.startsWith(
                                                  '/confirm-subscription',
                                                )
                                              ? ['Subscription']
                                              : location.pathname.startsWith(
                                                    '/questionnaire',
                                                  )
                                                ? ['Templates']
                                                : location.pathname.startsWith(
                                                      '/integrations',
                                                    )
                                                  ? ['Integrations']
                                                  : ['Dashboard']
          }
          // redirectUrl= {location.pathname.startsWith('/billing-payment') &&  '/billing-subscription' }
          redirectUrl={
            location.pathname.startsWith('/billing-payment')
              ? '/billing-subscription'
              : location.pathname.startsWith('/projects/') &&
                  location.pathname.split('/').length >= 3
                ? '/projects'
                : null
          }
     
  
        />
      </div>
      <div className={HeaderStyles.Dashboard_header__avatar_container}>
        {/* <SearchInput /> */}
        <Notifications />
        {/* <span className={HeaderStyles.Dashboard_header__settings}>
          <FiSettings />
        </span> */}
        <Profile />
      </div>
    </div>
  );
};

export default DashboardHeader;
